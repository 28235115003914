import React from "react";
import DeliveryFee from "../../views/home_page/delivery_fee";
import styles from "./styles.module.css";

function MSM_DeliveryFee({ stateAction }) {
  return (
    <div id="msm_shipping_fee" className={styles.container}>
      <i
        onClick={() => {
          stateAction(false);
        }}
        className="fa-solid fa-xmark"
      ></i>

      <div className={styles.body}>
        <DeliveryFee />
      </div>
    </div>
  );
}

export default MSM_DeliveryFee;
