import UseTranslation from "next-translate/useTranslation";
import Head from "next/head";
import Slider from "../components/slider/index";
import Announcement from "../components/views/home_page/announcement/index";
import MobileShortcut from "../components/views/home_page/mobile_shortcut";
import News from "../components/views/home_page/news/index";
import { GuidEmpty, Language, LanguageUid } from "../libs/enums";
import { API_BASE_URL } from "../libs/generalSettings";

const Home = (props) => {
  const { t } = UseTranslation("GetLanguageResourceResponse");
  const { announcements, news, sliders } = props;

  return (
    <>
      <Head>
        <title>{t(LanguageUid.TITLE)}</title>
      </Head>

      <Slider sliders={sliders} panel={true} butons={true} />
      <MobileShortcut />
      <Announcement
        announcements={announcements}
        title={true}
        viewAllUrl={"duyurular"}
        typeIsActive={true}
      />
      <News newsList={news} />
    </>
  );
};

export const getServerSideProps = async (context) => {
  const sliderRequest = await fetch(
    `${API_BASE_URL}/Slider/getBySourceUid?Uid=${GuidEmpty}&IsAdminPage=${false}&LanguageId=${
      Language.LanguageId[context.locale.toUpperCase()]
    }`
  );

  const sliderResponse =
    sliderRequest == null ? [] : await sliderRequest.json();

  const announcementsRequest = await fetch(
    `${API_BASE_URL}/Announcement/getByCountAndTypeAnnouncement?RealmId=${
      context.query.realmId ?? 1
    }&count=8&LanguageId=${Language.LanguageId[context.locale.toUpperCase()]}`
  );
  const announcementsResponse = await announcementsRequest.json();

  const newsRequset = await fetch(
    `${API_BASE_URL}/News/getByCountAndType?count=10&LanguageId=${
      Language.LanguageId[context.locale.toUpperCase()]
    }`
  );
  const newsResponse = await newsRequset.json();

  return {
    props: {
      sliders: sliderResponse,
      announcements: announcementsResponse,
      news: newsResponse,
    },
  };
};

export default Home;
